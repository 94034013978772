<template>
  <div>
    <TableList :unities="unities" @search="search"/>
  </div>
</template>

<script>
export default {
  components: {
    TableList: () => import("./components/TableList"),
  },
  computed: {
    unities() {
      return this.$store.state.unities.unities;
    },
  },
  methods:{
    search(v){      
      this.$store.dispatch('unities/get_filtered_unities', v)  
    }
  },
  mounted() {
    this.$store.dispatch("unities/unities_init");
  },
};
</script>

<style>
</style>